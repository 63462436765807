import React from "react"
import { Button } from "src/components/Button"

export const HasuraMeetup1 = () => (
  <div>
    <h2>HasuraMeetup #1</h2>
    <Button
      url={
        "https://www.youtube.com/playlist?list=PLs27JSo0NJ9MskGKPERrJQxI9UqI2bUfK"
      }
      text={"動画でチェック！"}
    />
  </div>
)
