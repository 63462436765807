import React from "react"
import { Button } from "src/components/Button"

export const HasuraMeetup2 = () => (
  <div>
    <h2>HasuraMeetup #2</h2>
    <Button
      text={"資料と動画をチェック！"}
      url={"https://uncle-javascript.com/hasura-japan-user-group-2"}
    />
  </div>
)
